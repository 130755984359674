import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { Countries } from '../model/countries';
import { States } from '../model/states';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ApiCountryStateService {
      baseUrl = environment.baseUrl;
    constructor(private http: HttpClient) {

    }

    /** GET Counties from the server */
    getCounties(): Observable<Countries[]> {
        let url: string = this.baseUrl + 'country';
        return this.http.get<Countries[]>(url)
            .pipe(
            tap(countries => this.log(`fetched coutries`)),
            catchError(this.handleError('getCounties', []))
            );
    }

    /** GET States from the server */
    getStates(): Observable<States[]> {
        let url: string = this.baseUrl + 'state';
        return this.http.get<States[]>(url)
            .pipe(
            tap(countries => this.log(`fetched States`)),
            catchError(this.handleError('getStates', []))
            );

    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            this.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    private log(message: string) {
    }
}