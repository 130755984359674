﻿import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { AuthenticationService } from '../../../auth/_services';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Program } from "../model/program";
import { map } from 'rxjs/operators'
import "rxjs/Rx";
import { RequestOptions, CommonService } from "../../../shared/service/common.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class ProgramService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
  ) { }

  /** Add and Edit Program from the server */
  addUpdateProgram(program: Program) {
    const urlSearchParams = this.commonService.getURLSearchParamsObject(program);
    const body = urlSearchParams.toString();
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    if (program.id) {
      const a = this.http.put(this.baseUrl + "program/" + program.id, body, options);
      return a.pipe(map((response: any) => {
        const data = response && response.data;
        return response;
      }));
    } else {
      const a = this.http.post(this.baseUrl + "program", body, options);
      return a.pipe(map((response: any) => {
        const data = response && response.data;
        return response;
      })).catch(this.commonService.GetHandleErrorString);
    }
  }

  /** GET program from the server */
  getProgramList(page: number, query: string) {
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .get(
      this.baseUrl + "program?limit=10&offset=" + (page - 1) * 10 + "&q=" + query, options).pipe(map((response: any) => response));
  }

  /** GET program by id from the server */
  getProgramDetail(id: number) {
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .get(this.baseUrl + "program/" + id, options)
      .pipe(map((response: any) => response));
  }

  /** Delete program from the server */
  deleteProgram(id: number) {
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .delete(this.baseUrl + "program/" + id, options)
      .pipe(map((response: any) => {
        return response;
      })).catch(this.commonService.GetHandleErrorString);
  }

  /** GET program from the server for registration*/
  getAllPrograms() {
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .get(
      this.baseUrl + 'program', options).pipe(map((response: any) => response));
  }
}
