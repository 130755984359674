export class Registration {
  id:any;
  sibling_name: string;
  center_id: number;
  program_id: string ='';
  first_name: string;
  last_name: string;
  date_of_birth: any;
  gender: string ='';
  photo: string;
  created_at: any;
  image_url: any;

  medical_problem: string;
  special_requirement: string;

  language: string;
  custody: string = '';
  notes: string;
  isFatherPrimary: boolean;
  isMotherPrimary: boolean;
  isGuardianPrimary: boolean;

  primary_parent_first_name: string;
  primary_parent_last_name: string;
  primary_parent_relation: string;
  primary_parent_is_release_child: string;
  primary_parent_email: string;
  primary_parent_phone_number: string;
  primary_parent_mobile_number: string;
  primary_parent_address_line1: string;
  primary_parent_address_line2: string;
  primary_parent_country: string;
  primary_parent_state: string;
  primary_parent_city: string;
  primary_parent_zip_code: string;
  maxDate: {year: number; month: number; day: number;};
  minDate: {year: number; month: number; day: number;
  };
}


export class Doctor {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;

}

export class Dentist {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;

}

export class Immunization {
  bcg_dose0: any;
  hepB_dose0: any;
  hepB_dose1: any;
  hepB_dose2: any;
  poliovirus_dose0: any;
  poliovirus_dose1: any;
  poliovirus_dose2: any;
  dtp_dose0: any;
  dtp_dose1: any;
  dtp_dose2: any;
  dtp_dose3: any;
  dtp_dose4: any;
  hib_dose0: any;
  hib_dose1: any;
  hib_dose2: any;
  hib_dose3: any;
  pcv_dose0: any;
  pcv_dose1: any;
  pcv_dose2: any;
  pcv_dose3: any;
  rv_dose0: any;
  rv_dose1: any;
  rv_dose2: any;
  typhoid_dose0: any;
  typhoid_dose1: any;
  mmr_dose0: any;
  mmr_dose1: any;
  varicella_dose0: any;
  varicella_dose1: any;
  hepA_dose0: any;
  hepA_dose1: any;
  tdap_dose0: any;
  hpv_dose0: any;
  hpv_dose1: any;
  hpv_dose2: any;
  maxDate4: { year: number; month: number; day: number; };
  minDate4: { year: number; month: number; day: number;
  };

}

export class ParentFather{
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
}

export class ParentFatherWorkplace{
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
}

export class ParentMother{
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
}

export class ParentMotherWorkplace{
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
}

export class ParentGuardian{
  first_name: string;
  last_name: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
  email: string;
}

export class ParentGuardianWorkplace{
  email: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
  phone_number: string;
  mobile_number: string;
}

export class Parent{
  isPrimary: string;
}

export class PrimaryContact{
  first_name: string;
  last_name: string;
  relation: string = '';
  is_release_child: string = '';
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;

}

export class SecondaryContact{
  first_name: string;
  last_name: string;
  relation: string = '';
  is_release_child: string = '';
  email: string;
  phone_number: string;
  mobile_number: string;
  address_line1: string;
  address_line2: string;
  country: string = '';
  state: string = '';
  city: string;
  zip_code: string;
}

export class AdditionalInfo{
  document_title: string;
  document_file: string;
  document_title1: string;
  document_file1: string;
}
