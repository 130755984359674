﻿import { environment } from '../../../../environments/environment';
import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { Staff } from "./../model/staff.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../../../auth/_services/authentication.service";
import "rxjs/Rx";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RequestOptions, CommonService } from "../../../shared/service/common.service";

@Injectable()
export class StaffService {
   baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
  
  ) { }

  getStaffList(page: number, query: string, pagination: boolean = true) {
    const urlSearchParams = this.commonService.getURLSearchParamsObject(query);
    const body = urlSearchParams.toString();
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .get(
      this.baseUrl +
      "staff?limit=10&offset=" +
      (page - 1) * 10 +
      "&q=" +
      query +
      "&pagination=" +
      pagination,
      { headers }
      ).pipe(map((response: any) => response));
  }

  getStaffDetail(id: number) {
    const headers = this.commonService.getHeadersObject(null, true, false);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .get(this.baseUrl + "staff/" + id, options)
      .map((response: any) => response)
      .catch(this.commonService.GetHandleErrorString);
  }

  updateStatus(id: number, status: string) {
    const arrayStatus = [{ status: status }];
    const urlSearchParams = this.commonService.getURLSearchParamsObject(arrayStatus[0]);
    const body = urlSearchParams.toString();
    const headers = this.commonService.getHeadersObject(null, true, true);
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.baseUrl + "staff/" + id + "/status",
      body,
      options
    ).map((response: any) => {
      const data = response && response.data;
      return response;
    }).catch(this.commonService.GetHandleErrorString);
  }

  AddStaffandUpdate(staff: Staff, file: any, Id: number) {
    const urlSearchParams = this.commonService.getURLSearchParamsObject(staff);
    const body = urlSearchParams.toString();
    const headers = this.commonService.getFormDataHeader();
    const options = new RequestOptions({ headers: headers });
    const formData = new FormData();
    formData.append("photo", file);   
    formData.append("center_id", this.authenticationService.getUserDetail().center_id);
    formData.append("first_name", staff.first_name);
    formData.append("last_name", staff.last_name);
    formData.append("join_date", staff.join_date ? staff.join_date : "");
    formData.append("gender", staff.gender);
    formData.append("email", staff.email);
    formData.append("phone_number", staff.phone_number ? staff.phone_number : "");
    formData.append("mobile_number", staff.mobile_number);
    formData.append("address_line1", staff.address_line1);
    formData.append("address_line2", staff.address_line2 ? staff.address_line2 : "");
    formData.append("country", staff.country);
    formData.append("state", staff.state);
    formData.append("city", staff.city);
    formData.append("zip_code", staff.zip_code);
    formData.append("designation", staff.designation);
    if (Id) {
      return this.http
        .post(this.baseUrl + "staff/" + Id, formData, options)
        .pipe(map((response: any) => {
          const data = response && response.data;
          return response;
        }));
    } else {
      return this.http
        .post(this.baseUrl + "staff", formData, options)
        .pipe(map((response: any) => {
          const data = response && response.data;
          return response;
        }));
    }
  }

  assignChild(assign_child: any, staff_id: any) {
    const urlSearchParams = this.commonService.getURLSearchParamsObject(assign_child);
    const body = urlSearchParams.toString();
    const headers = this.commonService.getHeadersObject(null, true, false);
    const options = new RequestOptions({ headers: headers });
    const child_id = assign_child.toString();
    const data = {};
    data["staff_id"] = ("" + staff_id) as string;
    data["child_id"] = ("" + child_id) as string;
    return this.http
      .post(this.baseUrl + "assign/", data, options)
      .map((response: any) => {
        return response;
      }).catch(this.commonService.GetHandleErrorString);
  }
}


